import React from 'react'

export default function Logo() {
  return (
    
    <div className="logo">
        <span role="img">🍿</span>
        <h1>usePopcorn</h1>
    </div>
    
  )
}
