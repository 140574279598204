import Search from './Search'
import Logo from './Logo'
import Result from './Result'



export default function Navbar({children}) {


    
    
  return (
   
    <nav className="nav-bar">
        {children}
    </nav>

    
  )
}

