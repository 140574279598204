import React, { useEffect, useState } from 'react'
import StarRaing from './StarRating'
import Loader from './Loader';



const kEY = "c24e6ce3";


export default function SelectMovie({ selectedId, onClose, onAdd, watched }) {


    const [selectMovie, setSelectMovie] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [userRating, setUserRating] = useState('')

    const {
        Title: title,
        Year: year,
        Poster: poster,
        Runtime: runtime,
        imdbRating,
        Plot: plot,
        Released: released,
        Actors: actors,
        Director: director,
        Genre: genre
    } = selectMovie

    useEffect(() => {

        async function fetchSelectMovie() {
            setIsLoading(true)
            const res = await fetch(`http://www.omdbapi.com/?apikey=${kEY}&i=${selectedId}`)
            const data = await res.json();
            console.log(data)
            setSelectMovie(data)
            setIsLoading(false)

        }

        fetchSelectMovie();
    }, [selectedId])


    function handleAdd() {
        const newWatched = {
            imdbID: selectedId,
            title,
            year,
            poster,
            imdbRating: Number(imdbRating),
            runtime: Number(runtime.split('').at(0)),
            userRating,
        }
        onAdd(newWatched)
        onClose();

    }

    const isShow = watched.map((watch) => watch.imdbID).includes(selectedId)
    const watchRating = watched.find((watch) => watch.imdbID === selectedId)?.userRating

    useEffect(() => {
        if (!title) return
        document.title = `Movie | ${title}`

        return function () {
            document.title = 'usePopcorn'
        }
    }, [title])

    return (
        <div className="details">
            {isLoading ? <Loader /> :
                <>
                    <header>
                        <button className='btn-back' onClick={onClose}>&larr;</button>
                        <img src={poster} alt={`poster of ${selectMovie}`} />
                        <div className='details-overview'>
                            <h2>{title}</h2>
                            <p>{released} &bull; {runtime}</p>
                            <p>{genre}</p>
                            <p><span>⭐</span>{imdbRating} IMDb rating</p>
                        </div>

                    </header>
                    <section>
                        <div className='rating'>
                            {isShow ? <p>You already rated this movie {watchRating}<span>⭐</span></p> :
                                <>
                                    <StarRaing maxRating={10} size={24} onSetRaing={setUserRating} />
                                    {userRating > 0 && (<button className='btn-add' onClick={handleAdd}>Add to List</button>)}
                                </>}
                        </div>
                        <p><em>{plot}</em></p>
                        <p>Starring {actors}</p>
                        <p>Directed by {director}</p>
                    </section>
                </>}
        </div>
    )
}
